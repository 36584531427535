.carter-main {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 7;
    top: 0;
    background-color: rgba(128, 128, 128, 0.231);
    transition: opacity 0.4s, visibility 0.4s;
    opacity: 1; /* Ensure the initial opacity is set */
    visibility: visible; /* Ensure the initial visibility is set */
}

.carter {
    width: 400px;
    height: 100vh;
    position: fixed;
    top: 0;
    display:flex;
    justify-content: center;
    padding-top:80px;
    right: 0;
    background-color: white;
    box-shadow: 0px 0px 0px 0.2px gray;
    transition: transform 0.4s, width 0.4s, height 0.4s; /* Include other properties in transition */
    transform: translateX(0%);
}
.dummy{
    font-weight:bold;
    font-size:24px;
}
.hide {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s, visibility 0.4s;
}

.movex {
    transform: translateX(100%);
}

.cross{
    font-size:20px;
    right:8px;
    top:8px;
    position:absolute;
    z-index: 7;
    cursor: pointer;

}

@media only screen and (max-width:768px) {
    .carter{
        width:100vw;
    }
}