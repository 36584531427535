/* Adjusting Navbar */
.navbar {
    display: flex;
    width: 100vw;
    height: 100px;
    /* background-color: gray; */
    align-items: center;
    justify-content: center;
    background-color: white;
    position:relative;
    z-index:5;
}

.inner-nav {
    width: 80%;
    /* background-color: beige; */
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.4s;
}
.child-1{
    display:flex;
    align-items: center;
    height:100%;

}
.hamburg{
    display:none;
}

.logo-hero {
    height: 90%;
    cursor:pointer;
}

.options {
    display: flex;
    align-items: center;
    gap: 36px;
    
}

.option {
    cursor: pointer;
    position: relative;
    z-index: 0;
    transition: 0.2s;
}

.option.selected-option::after {
    display: block;
    height: 2px;
    width: 100%;
    background-color: rgb(148, 94, 220);
    position: absolute;
    bottom: -6px;
    content: '';
}

.cart-opener {
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
}

.selected-option {
    color: rgb(148, 94, 220);
}

.Join-now {
    width: 100vw;
    height: 50px;
    background-color: rgb(37, 6, 79);
    color: white;
    display: flex;
    align-items: center;
    position:relative;
    gap:4px;

    justify-content: center;
    
}
.Join-now div{
    animation:bottom-to-up 0.4s linear;
    position:relative;
    
}
@keyframes bottom-to-up{
    0%{
        top:8px;
        opacity:0;
    }
    100%{
        top:0px;
        opacity:1;
    }
}

.Join-now a {
    color: rgb(235, 168, 134);
    border-bottom:1px solid rgb(215, 196, 89)
}

.rock-gif {
    border-radius: 50%;
    height: 40px;
}

/* Adjusting Navbar Width for Multiple Screen Adjustments */

@media only screen and (max-width: 1296px) {
    .inner-nav {
        width: 90%;
    }
}

@media only screen and (max-width: 1024px) {
    .inner-nav {
        width: 98% !important; /* Adding !important to ensure override */
    }
    .Join-now{
        font-size: 12px;
    }
}

@media only screen and (max-width: 1428px) {
    .inner-nav {
        width: 80%;
    }
}
.dropdown{
    display:none;
}




/*  Navbar Hamburger alignment disply flex          */
@media only screen and (max-width:1000px){
    .options{
        display:none;
    }
    .navbar{
        height:70px;
    }
    
    .hamburg{
        display:flex;
        flex-direction: column;
        gap:6px;
        align-items: center;
        justify-content: center;
        height:100%;
        width:60px;
        cursor:pointer;
    }
    .line-1,.line-2,.line-3{
        height:1.5px;
        position:relative;
        width:24px;
        background-color: black;
        transition: 0.4s;
        
    }
    .line-1-cust{
        transform: rotate(45deg);
        top:3.5px
    }
    .line-2-cust{
        display: none;
    }
    .line-3-cust{
        transform: rotate(-45deg);
        bottom:3.5px;
    }
}






/* Designing Dropdown   */
@media only screen and (max-width:1000px){
    .dropdown{
        display:flex;
        height:200px;
        flex-direction: column;
        justify-content: center;
        gap:24px;
        align-items: flex-end;
        width:100%;
        padding-right:24px;
        position:absolute;
        top:70px;
        background-color: rgb(255, 255, 255);
        transform: translateY(-100%);
        z-index:4;
        transition:0.4s;
        box-shadow:0px 0px 10px 0px gray;
    }
    .dropdown-display{
        transform: translateY(0%);
    }
}
