.offer-main{
    height:auto;
    width:100vw;
    position:relative;
    display:flex;
    align-items: center;
    justify-content: center;
    padding:60px 0px 60px 0px;
    background-color: rgb(143,88,217);
    color:white;

}
.offer{
    align-items: center;
    display:flex;
    width:80%;
    flex-direction: column;
    justify-content: center;
    gap:96px;
    padding:50px 0px 80px 0px;
    min-height:100vh;
}
.about{
    display:flex;
    align-items: center;
    justify-content: space-between;

    
}

.flex-col{
    display:flex;
    flex-direction: column;
}
.c-1{
    font-size: 36px;
    font-weight: bold;
}
.c-2{
    font-size: 24px;
}
.quality{
    width:40%;
    font-size:20px;
}





.features{
    display:flex;
    height:auto;
    gap:24px;
    min-height:500px;
    width:90vw



}
.features img{
    height:260px;
    width:250px;
    border-radius:12px;
    
}
.feature{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width:25%;
    transition:0.2s;
    opacity: 0;
    position:relative;
    top:12px;
    gap:24px;
    top:0px;
    position:relative;
}
.show{
    opacity:1;
    animation:down-to-up 0.6s linear
    
}
@keyframes down-to-up {
    0%{
        position:relative;
        top:24px;
    }
    100%{
        top:0px;
    }
}
.feature-end{
    
    justify-content: flex-end;
 

}
.feature-desc{
    display:flex;
    align-items: center;
    gap:16px;

}
.off-num{
    font-size: 36px;
    color:rgb(243,175,136)
}
.big-font{
    font-size: 22px;
}
.offer-btn{
    width:250px;
    height:40px;
    border:none;
    border-radius: 12px;
    color:black;
    background-color: white;
    font-size: 18px;
    cursor:pointer;
}

.desc-btn{
    display:flex;
    flex-direction: column;
    gap:24px;
}
@media only screen and (max-width:1200px){
    .about{
        flex-direction: column;
        gap:24px;
        text-align: center;
    }
    .flex-col{
        gap:6px;
    }
    .quality{
        width:80%;
    }
    
}




@media only screen and (max-width:1200px){
    .features{
        height:auto;
        flex-direction: column;
       align-items: center;
       width:100vw;
       gap:72px;
    }
    
    /* .feature-one.show{
        opacity:1;
        animation:r-t-l 0.5s linear
        
    }
    @keyframes r-t-l {
        0%{
            transform: translateX(-100%);
        }
        100%{
            transform: translateX(0);
        }
    }
    .feature-end.show{
        opacity:1;
        animation:l-t-r 0.5s linear
        
    }
    @keyframes l-t-r {
        0%{
            transform: translateX(100%);
        }
        100%{
            transform: translateX(0);
        }
    } */
    .feature{
        flex-direction: row;
        width:70%;
        justify-content: center;
        
    }
   
    .desc-btn{
        width:30%;
    }
    

    .offer-btn{
        width:100%;
    }
    .desc-btn{
        display: flex;
        flex-direction: column;
    }
    .feature-end{
        justify-content: center;
        flex-direction: row-reverse;

    }
    
}



@media only screen and (max-width:800px){
    .about{
        gap:32px;
    }
    .quality{
        font-size:18px;
    }
    .feature{
        width:95vw;
    }
    .feature img{
        width:200px;
        height:200px;
        
        
    }
    .desc-btn{
        width:50%;
        align-items: center;
        text-align: center;

        
    }
    .feature-desc{
        flex-direction: column;
        align-items: flex-start;
        text-align: left;

  
    }
    .feature-end .feature-desc{
        align-items: flex-end;
        text-align: right;
    }

    .off-num{
        font-size: 48px;
    }
    .offer-btn{
        
        width:80%;
        font-size: 16px;
    }
    .big-font{
        font-size: 20px;
        
    }
}








@media only screen and (max-width:400px){
    .feature{
        flex-direction: column;
    }
    .feature-desc{
        flex-direction: column;
        align-items: center;
        text-align: center;

  
    }
    .feature-end .feature-desc{
        align-items: center;
        text-align: center;
    }

    
}
