.footer{
    width:100vw;
    padding:20px 0px 40px 0px ;
    height:auto;
    background-color: rgb(255, 255, 255);
    display:flex;
    align-items: center;
    justify-content: center;
}
.inner-footer{
    width:80%;
    display:flex;
    align-items: center;
    justify-content: space-around;
}

.logo-social img{
    height:200px;width:200px;
    


}
.logo-social{
    display:flex;
    flex-direction: column;
    align-items: center;
}
.social{
    display:flex;
    justify-content: center;
    gap:12px;
    font-size:18px;
    width:50%;
}

.rest{
    display:flex;
    align-items: center;
    width:50%;
    justify-content: space-between;


}

@media only screen and (max-width:900px){
    .inner-footer{
        flex-direction: column;
        gap:48px;
        
    }
    .logo-social{
        align-items: center;
        justify-content: center;
        width:100%;
    }
    .rest{
        text-align: center;
        flex-direction: column;
        justify-content: center;
        gap:48px;
        width:100%;
    }
}