.connect{
    height:auto;
    width:100vw;
    padding:40px 0px 20px 0px;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:36px;
    animation:btu 0.4s linear;
}
@keyframes btu{
    0%{
        position:relative;
        top:36px;
    }
    100%{
        position:relative;
        top:0px;
    }
}

.contact-head{
    text-align: center;
    display:flex;
    flex-direction: column;
    gap:18px;
    align-items: center;
}
.heading{
    font-weight:bold;
    font-size:48px;
    color:rgb(99,27,196)
}
.brief-contact{
    width: 40%;
    
    line-height: 24px;
}



.form-location{
    display:flex;
    align-items: center;
    justify-content: center;
    width:100vw;
}

.form{
    display:flex;
    flex-direction: column;
    gap:24px;
    width:40%;
    


}
.name,.phone,.message,.email{
    display:flex;
    flex-direction: column;
    gap:2px;
    width:80%;

}
.name input,.phone input,.email input{
    height:40px;
    width:100%;
    outline:none;
    border:none;
    background-color: rgb(241,241,241);
    padding:6px;
    border-radius: 4px;
    padding:8px;
    color:gray
}
.message textarea{
    resize:none;

    height:120px;
    width:100%;
    border-radius: 4px;
    outline:none;
    border:none;
    color:gray;
    background-color: rgb(241,241,241);
    padding:8px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.form button{
    width:30%;
    height:50px;

    border:none;
    border-radius: 4px;
    background-color:rgb(143,88,217) ;
    color:white;
    font-size: 16px;
    cursor:pointer

}



@media only screen and (max-width:1174px){
    .form-location{
        flex-direction: column;
        gap:24px;
    }
    .form{
        width:100%;
       
        align-items: center;
    }
    
}
@media only screen and (max-width:700px){
    .map{
        width:90vw;
        margin:auto;
    }
    .brief-contact{
        width:80vw;
    }
}
