.coming-soon{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height:auto;
    width:auto;
    gap:100px;
    padding:100px 0px 100px 0px ;
   
    position:relative;

}

.coming-soon div{
    color:rgb(99,27,196);
    font-size:56px;
    font-weight: bold;
    
    position:relative;
}
.coming-soon button{
    color:white;
    background-color: rgb(143,88,217);
    height:60px;
    width:160px;
    border:none;
    font-size:16px;
    border-radius:8px;
    cursor: pointer;
    
}


@media only screen and (max-width:800px){
    .coming-soon{
        gap:40px;
        padding:20px 0px 20px 0px;
        text-align: center;
    }
    .coming-soon div{
        font-size: 36px;
    }
   
}

