.intro-hero{
    height:auto;
    width:100vw;
    position:relative;
    display:flex;
    align-items: center;
    justify-content: center;
    padding:60px 0px 60px 0px;
    /* background-color: green; */
}
.inner-intro-hero{
    display:flex;
    width:80%;

    height:auto;
    /* background-color: rgb(255, 255, 255); */
    align-items: center;
}
.hero-text{
   width:50%  ; 
    display:flex;

    
    flex-direction: column;
    gap:64px;
}
.leading{
    color:
    rgb(148,94,220);
    font-weight:600;
    font-size:24px;
    animation:bottom-to-up 0.4s linear;
    position:relative;
}
.introd{
    font-weight:bold;
    font-size:48px;
    width:100%;
    line-height: 56px;
    animation:bottom-to-up-2 0.4s linear;
    position:relative;
}
.scan-ratings{
    position:relative;
    width:auto;
    height:auto;
}
.hero-text img{
    position:absolute;
    left:-60px;
    bottom:-120px;
    height:340px;
    width:350px;
    z-index:-1;
}
.scan{
    border:none;
    width:260px;height:50px;
    border-radius: 8px;
    background-color: rgb(148,94,220);
    color:white;
    font-size:16px;
    animation:bottom-to-up 0.4s linear;
    position:relative;
    cursor:pointer;
}

.img-grad{
    position:relative;
    height:auto;
    width:auto;
}
.img-top{
    position:absolute;
    right:0;
    top:0;
    height:500px;
    width:500px;
    top:-60px;
    right:0px;
    
    
}
.gradient-background {
    position:absolute;
    height:500px;
    width:500px;
    
    right:20px;
    top:0;
    border-radius: 50%;
    opacity:0.15;
    /* Create a radial gradient */
    background: radial-gradient(circle at center, #5a0fa0 0%, #022683 50%, #e2e2e236 100%);
  }

.land-img{
    height:500px;
    width:600px;
    animation:bottom-to-up-2 0.4s linear;
    position:relative;

}

@keyframes bottom-to-up-2{
    0%{
        top:48px;
        opacity:0;
    }
    100%{
        top:0px;
        opacity:1;
    }
}


/* Column display for hero 1st section and the text alignments */
@media only screen and (max-width:1170px){
    .inner-intro-hero{
        flex-direction: column;
    }
    .hero-text{
        align-items: center;
        text-align: center;
        gap:24px;
        width:auto;
        
    }
    .img-top{
        height:600px;
        width:300px;
        top:10px;
        right:50px;
    }
    .hero-text img{
        display:none;
    }
}


/* Mobile screen adjustments for the image and the hero text */

@media only screen and (max-width:784px){
    .intro-hero{
        padding:30px 0px 20px 0px;
    }
    .inner-intro-hero{
        gap:24px;
    }
    .land-img{
        
        width:100%;
        height:auto;
    }
    .leading{
        font-size:20px;
    }
    .introd{
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
    }
    .img-top{
        height:600px;
        width:300px;
        top:-24px;
        right:50px;
    }
   
    }
